import { FC, PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

import { IUnitsPriceAvailabilityInformation } from "../type";

const ExtraInfo: FC = () => {
  return <InfoIcon sx={(theme) => ({ color: theme.palette.grey[500] })} />;
};

export const Pricing: FC<
  PropsWithChildren<IUnitsPriceAvailabilityInformation>
> = (props) => {
  switch (true) {
    // There is a discounted price .. we show striked out price
    case Boolean(props.discountedPriceStart) &&
      props.discountedPriceStart! < props.startPrice:
      return (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          // justifyContent="flex-end"
        >
          <Typography
            variant="caption"
            color="GrayText"
            sx={{ textDecoration: "line-through", mr: 0.5 }}
          >
            {`${props.currencySymbol}${props.startPrice}/${props.pricePer}`}
          </Typography>
          <Typography
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
            variant="subtitle1"
          >
            <Typography
              variant="h6"
              // sx={{ fontWeight: "bold" }}
            >{`${props.currencySymbol}${props.discountedPriceStart}`}</Typography>
            {`/${props.pricePer}`}
          </Typography>
          <ExtraInfo />
          {props.children}
        </Box>
      );
    // its a range .. so we show Starts from
    case Boolean(props.startPrice) && Boolean(props.endPrice):
      return (
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="caption" color="GrayText" sx={{ mr: 0.5 }}>
            Starts at
          </Typography>
          <Typography
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
            variant="subtitle1"
          >
            <Typography
              variant="h6"
              // sx={{ fontWeight: "bold" }}
            >{`${props.currencySymbol}${props.startPrice}`}</Typography>
            {`/${props.pricePer}`}
          </Typography>
          <ExtraInfo />
          {props.children}
        </Box>
      );
    default:
      return (
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
            variant="subtitle1"
          >
            <Typography
              variant="h6"
              // sx={{ fontWeight: "bold" }}
            >{`${props.currencySymbol}${props.startPrice}`}</Typography>
            {`/${props.pricePer}`}
          </Typography>
          <ExtraInfo />
          {props.children}
        </Box>
      );
  }
};
