import { FC } from "react";
import { IReservationForm, IShortForm, IContactUsForm } from "../type";
import { ShortForm } from "./ShortForm";
import { ContactUs } from "./ContactUs";

export const Forms: FC<IReservationForm> = ({ typeOfForm, ...formProps }) => {
  switch (typeOfForm) {
    case "reservation":
    default:
      return (
        <ShortForm {...formProps} formData={formProps.formData as IShortForm} />
      );
    case "contactUs":
      return (
        <ContactUs
          {...formProps}
          formData={formProps.formData as IContactUsForm}
        />
      );
  }
};
