import { FC, useEffect } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { IReservationForm, IContactUsForm } from "../type";
import { useConfig } from "../../../context/ConfigProvider";
import { EVENTS } from "../../../constants";

export const ContactUs: FC<
  Omit<IReservationForm<IContactUsForm>, "typeOfForm">
> = (props) => {
  const { triggerEvent } = useConfig();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IContactUsForm>({
    defaultValues: {
      firstName: props.formData?.firstName || "",
      lastName: props.formData?.lastName || "",
      email: props.formData?.email || "",
      phoneNumber: props.formData?.phoneNumber || "",
      notes: props.formData?.notes || "",
    },
  });

  useEffect(() => {
    triggerEvent(EVENTS.ON_RESERVATION_FORM_VALIDATION_ERROR, errors);
  }, [errors]);

  const onSubmit: SubmitHandler<IContactUsForm> = props.onFormDataSubmit;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Controller
          name="firstName"
          rules={{
            required: { value: true, message: "First Name is required" },
          }}
          control={control}
          render={({ field: { ref, ...otherFieldProps } }) => (
            <Grid item xs={12}>
              <TextField
                fullWidth
                inputRef={ref}
                autoComplete="given-name"
                label="First Name"
                placeholder="First Name"
                error={errors.firstName ? true : undefined}
                helperText={errors.firstName?.message || ""}
                {...otherFieldProps}
              />
            </Grid>
          )}
        />
        <Controller
          name="lastName"
          rules={{
            required: { value: true, message: "Last Name is required" },
          }}
          control={control}
          render={({ field: { ref, ...otherFieldProps } }) => (
            <Grid item xs={12}>
              <TextField
                fullWidth
                inputRef={ref}
                autoComplete="family-name"
                label="Last Name"
                error={errors.lastName ? true : undefined}
                helperText={errors.lastName?.message || ""}
                placeholder="Last Name"
                {...otherFieldProps}
              />
            </Grid>
          )}
        />
        <Controller
          name="email"
          rules={{
            required: { value: true, message: "Email is required" },
            pattern: {
              value:
                /^(?!\.)(?!.*\.{2})([a-zA-Z0-9._+-]+(?<!\.))@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
              message: "Invalid email address",
            },
          }}
          control={control}
          render={({ field: { ref, ...otherFieldProps } }) => (
            <Grid item xs={12}>
              <TextField
                fullWidth
                autoComplete="email"
                label="Email"
                inputRef={ref}
                error={errors.email ? true : undefined}
                helperText={errors.email?.message || ""}
                placeholder="Email"
                {...otherFieldProps}
              />
            </Grid>
          )}
        />
        <Controller
          name="phoneNumber"
          rules={{
            required: { value: true, message: "Phone Number is required" },
            maxLength: { value: 10, message: "Invalid Phone Number" },
          }}
          control={control}
          render={({ field: { ref, ...otherFieldProps } }) => (
            <Grid item xs={12}>
              <TextField
                fullWidth
                inputRef={ref}
                autoComplete="tel"
                label="Phone Number"
                placeholder="Phone Number"
                error={errors.phoneNumber ? true : undefined}
                helperText={errors.phoneNumber?.message || ""}
                {...otherFieldProps}
              />
            </Grid>
          )}
        />
        <Controller
          name="notes"
          control={control}
          render={({ field: { ref, ...otherFieldProps } }) => (
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                inputRef={ref}
                label="Questions"
                placeholder="Questions"
                error={errors.notes ? true : undefined}
                helperText={errors.notes?.message || ""}
                {...otherFieldProps}
              />
            </Grid>
          )}
        />
        <Grid item xs={12}>
          <Button disabled type="submit" fullWidth variant="contained">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
