import { FC, useMemo } from "react";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import {
  useUnitInformation,
  ISortOption,
} from "../context/UnitsInformationProvider";
import { Icon } from "./Icon";

export const FilterInformation: FC<{ open: boolean }> = (props) => {
  const {
    unitGroups,
    sortOption,
    filters,
    setSortOption,
    selectFilter,
    priceRange,
    priceRangePossible,
    onPriceRangeChange,
    clearFilters,
  } = useUnitInformation();

  const handlePriceChange = (
    _event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      onPriceRangeChange([Math.min(newValue[0], priceRange[1]), priceRange[1]]);
    } else {
      onPriceRangeChange([priceRange[0], Math.max(newValue[1], priceRange[0])]);
    }
  };

  const filtersCount = useMemo(() => {
    let count = filters.length;
    if (
      priceRange[0] !== priceRangePossible[0] ||
      priceRange[1] !== priceRangePossible[1]
    ) {
      count++;
    }
    return count;
  }, [filters, priceRange, priceRangePossible]);

  return (
    <Collapse in={props.open}>
      <Box sx={{ p: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="row-reverse"
              justifyContent="flex-start"
              alignItems="center"
              flexWrap="wrap"
            >
              {filtersCount && (
                <Button variant="text" onClick={clearFilters}>
                  Clear All
                </Button>
              )}
              {(priceRange[0] !== priceRangePossible[0] ||
                priceRange[1] !== priceRangePossible[1]) && (
                <Button
                  sx={(theme) => ({ m: 1, color: theme.palette.grey[500] })}
                  color="info"
                  variant="text"
                  onClick={() => {
                    onPriceRangeChange(priceRangePossible);
                  }}
                >
                  {`Price: $${priceRange[0]} - $${priceRange[1]}`}
                  <CloseIcon color="inherit" fontSize="inherit" />
                </Button>
              )}
              {filters.map((filter) => (
                <Button
                  key={filter.property + filter.value}
                  sx={(theme) => ({ m: 1, color: theme.palette.grey[500] })}
                  color="info"
                  variant="text"
                  onClick={() => {
                    selectFilter(filter);
                  }}
                >
                  {filter.label}
                  <CloseIcon color="inherit" fontSize="inherit" />
                </Button>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>Sort By</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ mb: 1 }}>
              <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
              <Select
                fullWidth
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sortOption.property}
                label="Sort By"
                onChange={(ev) => {
                  setSortOption({
                    property: ev.target.value as ISortOption["property"],
                    order: "asc",
                    label: ev.target.value === "size" ? "Unit Size" : "Price",
                  });
                }}
                // onChange={handleChange}
              >
                <MenuItem value="size">Unit Size</MenuItem>
                <MenuItem value="price">Price</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>Sizes</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={(theme) => ({
                flexDirection:
                  theme.direction === "ltr" ? "row" : "row-reverse",
                flexWrap: "wrap",
                mb: 1,
              })}
            >
              {unitGroups.map((group) => (
                <Button
                  sx={{ mr: 1, mb: 1 }}
                  variant={
                    filters.find(
                      (x) => x.value === group.size && x.property === "size"
                    )
                      ? "contained"
                      : "outlined"
                  }
                  key={group.id}
                  onClick={() => {
                    selectFilter({
                      property: "size",
                      value: group.size,
                      label: `${group.length}’ x ${group.width}’`,
                    });
                  }}
                >
                  {`${`${group.length}’ x ${group.width}’`}`}
                </Button>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>Amenities</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={(theme) => ({
                flexDirection:
                  theme.direction === "ltr" ? "row" : "row-reverse",
                flexWrap: "wrap",
                mb: 1,
              })}
            >
              {Array.from(
                new Set(
                  unitGroups.flatMap((group) =>
                    group.amenities.map((amenity) => amenity.name)
                  )
                )
              ).map((amenity: string) => (
                <Button
                  sx={{ mr: 1, mb: 1 }}
                  variant={
                    filters.find(
                      (x) => x.value === amenity && x.property === "amenities"
                    )
                      ? "contained"
                      : "outlined"
                  }
                  key={amenity}
                  onClick={() => {
                    selectFilter({
                      property: "amenities",
                      value: amenity,
                      label: amenity,
                    });
                  }}
                >
                  <Icon iconName={amenity.toLowerCase()} />
                  {amenity}
                </Button>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>Price Range</Typography>
          </Grid>
          <Grid item xs={12}>
            <Slider
              marks={[
                {
                  value: priceRangePossible[0],
                  label: `$${priceRangePossible[0]}`,
                },
                {
                  value: priceRangePossible[1],
                  label: `$${priceRangePossible[1]}`,
                },
              ]}
              // step={10}
              value={priceRange}
              min={priceRangePossible[0]}
              max={priceRangePossible[1]}
              onChange={handlePriceChange}
              valueLabelDisplay="on"
              valueLabelFormat={(value) => `$${value}`}
            />
          </Grid>
        </Grid>
      </Box>
    </Collapse>
  );
};
