import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { IUnitsInformation } from "./type";
import { UnitsImage } from "./subComponents/Image";
import { Amenities } from "./subComponents/Amenities";
import { Pricing } from "./subComponents/Pricing";

/**
 * This component is responsible for rendering the mobile view of the UnitsInformation.
 */
export const UnitsInformationMobile: FC<IUnitsInformation> = (props) => {
  return (
    <Box sx={{ display: { md: "none", lg: "none", xl: "none" }, p: 1 }}>
      <Box sx={{ width: "100%" }}>
        <Typography variant="h5">
          {props.name || `${props.size.length}’ x ${props.size.width}’`}
        </Typography>
        <Box
          sx={(theme) => ({
            display: "flex",
            mb: 1,
            flexDirection: theme.direction === "ltr" ? "row" : "row-reverse",
          })}
        >
          <Box sx={{ flex: 1 }}>
            {props.availbleUnits <= 1 && (
              <Typography
                variant="caption"
                sx={(theme) => ({ color: theme.palette.secondary.main })}
              >{`${props.availbleUnits} unit available`}</Typography>
            )}
            <Pricing {...props} />
            <Amenities
              amenities={props.amenities}
              noOfAmenitiesToShow={props.noOfAmenitiesToShow}
            />
          </Box>
          <UnitsImage
            unitInformation={props}
            heightFactor={12}
            widthFactor={16}
            forceCarousel
          />
        </Box>
        <Button fullWidth variant="contained" onClick={props.onSelectClick}>
          {props.availbleUnits <= 1 ? "Contact Us" : "Select"}
        </Button>
      </Box>
    </Box>
  );
};
