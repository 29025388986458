export interface IUTConfiguration {
  omiUrl: string;
  omiPassMode: "paramter" | "query";
  reservationMessage: string;
}

export const defaultUTConfiguration: IUTConfiguration = {
  omiUrl: "http://localhost:3000",
  omiPassMode: "query",
  reservationMessage:
    "Your Reservation has been confirmed and the Id for the same is {{reservationId}}",
};
